
























































import Vue from "vue";
import MapLocationPicker from "@/components/shared/MapLocationPicker.vue";
export default Vue.extend({
  components: {
    MapLocationPicker
  },
  props: ["dialog", "ubicacion"],
  data() {
    return {
      ubicacionNueva: {
        lat: null,
        lng: null
      }
    };
  },
  methods: {
    locationChange(value: { lat: any; lng: any }) {
      this.ubicacionNueva = value;
    },
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      try {
        const loc = JSON.stringify(this.ubicacionNueva);
        console.log(loc);
        this.$emit("locationChange", loc);
      } catch {
        console.log("no se pudo guardar la nueva ubicación");
      }
    }
  }
});
