<template>
  <v-row>
    <v-col cols="12">
      <GmapMap
        :center="center"
        :zoom="18"
        map-style-id="roadmap"
        :options="mapOptions"
        style="height: 50vmin"
        ref="mapRef"
        @click="handleMapClick"
      >
        <GmapMarker
          :position="marker.position"
          :clickable="true"
          :draggable="true"
          @drag="handleMarkerDrag"
          @click="panToMarker"
        />
      </GmapMap>
      <!--
    <button @click="geolocate">Detect Location</button>
    <p>{{ marker.position }}</p>
    -->
    </v-col>
  </v-row>
</template>

<!--
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  
});
</script>
-->

<script>
export default {
  props: ["ubicacion"],
  data() {
    return {
      //marker: { position: { lat: -2.159178, lng: -79.898065 } },
      marker: { position: { lat: null, lng: null } },
      center: { lat: -2.159178, lng: -79.898065 },

      mapOptions: {
        disableDefaultUI: true
      }
    };
  },
  methods: {
    locationChange() {
      this.$emit("locationChange", this.marker.position);
    },
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };

        this.panToMarker();
      });
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.$refs.mapRef.setZoom(18);
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      //console.log(e);
    }
  },
  mounted() {
    //const ubicacionOld = JSON.parse(this.ubicacion? this.ubicacion : '{}')
    //this.marker.position = JSON.parse(this.value ? this.value : "");
    try {
      const pos = JSON.parse(this.ubicacion);
      if (pos && pos.lat && pos.lng) {
        this.marker.position = { lat: pos.lat, lng: pos.lng };
        //this.panToMarker();
      }
    } catch {
      this.marker.position = { lat: null, lng: null };
      console.log("no hay direccion para dibujar");
    }
    //this.geolocate();
  },
  watch: {
    "marker.position": function(newValue) {
      if (newValue && newValue.lat && newValue.lng) {
        this.locationChange();
      }
    }
  }
};
</script>
