

















































































































































































import Vue from "vue";
import Valid from "@/utils/valid";
import ProvinciaService from "@/api/pedido/ProvinciaService";
import UbicacionEnvioPicker from "./UbicacionEnvioPicker.vue";

export default Vue.extend({
  components: {
    UbicacionEnvioPicker
  },
  props: ["value"],
  data() {
    return {
      dialogMapPicker: false,
      valid: true,
      editedItem: {
        tipoIdentificacion: "",
        identificacion: "",

        nombre: "",
        apellido: "",

        provincia: "",
        canton: "",
        direccion: "",

        telefono: "",

        referencia: "",
        ubicacion: ""
      },
      tipoIdentificacionOptions: [
        { text: "Cédula", value: "CED" },
        { text: "RUC", value: "RUC" },
        { text: "Pasaporte", value: "PAS" }
      ],
      TextRules: [(v: string) => !!v || "Campo no debe estar vacio"],
      email: "",
      emailRules: [
        (v: string) => !!v || "E-mail is required",
        (v: string) => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],

      pasaporteRules: [(v: string) => !!v || "Campo requerido"],
      checkbox: false,

      provincias: [] as any
    };
  },

  methods: {
    locationNewSelected(value: string) {
      this.editedItem.ubicacion = value;
      this.dialogMapPickerChange(false);
    },
    dialogMapPickerChange(value: boolean) {
      this.dialogMapPicker = value;
    },
    openGoogleMaps() {
      window.open(
        "https://www.google.com/maps/@-2.0639725,-79.9752205,14z",
        "_blank"
      );
    },
    openGoogleMapsHelp() {
      window.open(
        "https://support.google.com/maps/answer/144361?co=GENIE.Platform%3DAndroid&hl=es",
        "_blank"
      );
    },
    onChangeProvincia() {
      this.editedItem.canton = "";
    },

    validate() {
      console.log(this.editedItem);
      //this.$refs.form.validate();
      const isValid = (this.$refs.form as Vue & {
        validate: () => boolean;
      }).validate();
      if (isValid) {
        this.continuar();
      }
    },
    continuar() {
      this.$emit("isComplete", true);
    },
    loadProvincias() {
      this.provincias = ProvinciaService.getProvincias();
    }
  },
  computed: {
    getCantones(): any {
      const data = this.provincias.filter(
        (c: any) => c.nombre === this.editedItem.provincia
      );
      if (data.length > 0) {
        return data[0].cantones;
      }
      return [];
    },
    getIdentidadRules: {
      get() {
        switch (this.editedItem.tipoIdentificacion) {
          case "CED":
            return [
              (v: string) => !!v || "Campo requerido",
              //(v: string) => Valid.identificacionValida(v) || "La cédula no es válida"
              (v: string) =>
                (v.length === 10 && Valid.identificacionValida(v)) ||
                "La cédula no es válida"
            ];
          case "RUC":
            return [
              (v: string) => !!v || "Campo requerido",
              (v: string) =>
                (v.length === 13 && Valid.identificacionValida(v)) ||
                "El RUC no es válido"
            ];
          default:
            return [(v: string) => !!v || "Campo requerido"];
        }
      }
    } //end get identidadRules
  },

  watch: {
    "editedItem.nombre": function(newVal: string) {
      this.editedItem.nombre = newVal.toUpperCase();
    },
    "editedItem.apellido": function(newVal: string) {
      this.editedItem.apellido = newVal.toUpperCase();
    },
    "editedItem.direccion": function(newVal: string) {
      this.editedItem.direccion = newVal.toUpperCase();
    },
    "editedItem.referencia": function(newVal: string) {
      this.editedItem.referencia = newVal.toUpperCase();
    }
  },

  created() {
    //this.editedItem = ;
    this.loadProvincias();
    this.editedItem = this.value; //mala practica pero como todo es limpio se value el binding doble
  }
});
