




















































import Vue from "vue";
import InformacionEnvio from "@/components/web/Pago/InformacionEnvio.vue";
import PedidoService from "@/api/pedido/PedidoService";

export default Vue.extend({
  components: {
    InformacionEnvio
  },
  props: ["dialog", "dato"],
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        id: 0,
        direccionEnvio: {
          tipoIdentificacion: "",
          identificacion: "",

          nombre: "",
          apellido: "",

          provincia: "",
          canton: "",
          direccion: "",

          telefono: "",

          referencia: "",
          ubicacion: "" //
        }
      }
    };
  },
  mounted() {
    //this.editedItem = { ...this.dato };
  },
  created() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    toComplete() {
      this.save();
    },
    save() {
      PedidoService.modificarMiPedido(this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Direccion",
            text: "Guardada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Dirección",
            text: "No se pudo guardar"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
